<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard
          title="Recordatorio de fechas importantes"
          :subtitle="`Tiene ${dates.length} fechas que quiere que le recuerden`"
        ></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="dates"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          rows-per-page-text="Fechas por pagina"
          no-data-text="No hay fechas ingresadas"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                {{ props.item.date | moment('D') }} /
                {{ props.item.date | moment('MMMM') }}
              </td>
              <td>
                {{ props.item.type }}
              </td>
              <td v-html="props.item.name"></td>
              <td>
                <v-btn icon ripple>
                  <v-icon color="error lighten-1" @click="deleteAction(props.item)">
                    delete_forever
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'
import { CLIENT } from '../../config'

export default {
  components: { titleCard },
  props: ['buyer'],
  data() {
    return {
      dateActive: {},
      dateDialog: false,
      dates: [],
      headers: [
        { text: 'Fecha', align: 'left', sortable: false },
        { text: 'Tipo', sortable: false },
        { text: 'Nombre', align: 'left', sortable: false },
        { text: '', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.getDatesUser()
  },
  methods: {
    async getDatesUser() {
      try {
        const res = await this.$http.get(`${CLIENT}/${this.buyer.id}/dates`)
        this.dates = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async delete(value) {
      try {
        await this.$http.delete(`${CLIENT}/${this.buyer.id}/dates/${value.id}`)
        this.$store.dispatch('setConfirm', { active: false })
        this.getDatesUser()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    status(e) {
      if (e) {
        return 'success'
      }
      return 'error'
    },
    setStatus(statusID) {
      return this._.filter(this.states, ['id', statusID])
    },
    editDate(item) {
      this.dateActive = item
      this.dateDialog = true
    },
    deleteAction(value) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar fecha?',
        description: 'Al cliente no se le recordara con un cupon esta fecha.',
        action: () => this.delete(value)
      })
    }
  }
}
</script>
